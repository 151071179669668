import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {alfDestinationTypes, alfRelevantLocationTypes} from '@ace-de/eua-entity-types';
import createInvoiceLine from './createInvoiceLine';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';

const initiateCreateInvoiceLineFlow = function* initiateCreateInvoiceLineFlow() {
    while (true) {
        yield take(invoiceActionTypes.INITIATE_CREATE_INVOICE_LINE_FLOW);

        yield* openModal(modalIds.INVOICE_LINE_DATA);

        const chosenModalOption = yield take([
            invoiceActionTypes.DECLINE_CREATE_INVOICE_LINE,
            invoiceActionTypes.CONFIRM_CREATE_INVOICE_LINE,
        ]);

        if (chosenModalOption.type === invoiceActionTypes.CONFIRM_CREATE_INVOICE_LINE) {
            const {payload} = chosenModalOption;
            const {invoiceId, invoiceLineData} = payload;

            yield* createInvoiceLine({
                caller: invoiceActionTypes.INITIATE_CREATE_INVOICE_LINE_FLOW,
                payload: {
                    invoiceId,
                    invoiceLineData: {
                        ...invoiceLineData,
                        ...(invoiceLineData.destination && {
                            destination: {
                                ...invoiceLineData.destination,
                                type: alfRelevantLocationTypes.SERVICE,
                                destinationType: alfDestinationTypes.OTHER_LOCATION,
                            },
                        }),
                    },
                },
            });
        }

        yield* closeModal(modalIds.INVOICE_LINE_DATA);
    }
};

export default initiateCreateInvoiceLineFlow;
