import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonIcon, NoResultsBlock, Paginator, Panel, Pill, RadioButton, RadioButtonGroup, useStyles} from '@ace-de/ui-components';
import {calendarIcon, Icon, findCaseIcon, InteractiveIcon, resetIcon, searchIcon} from '@ace-de/ui-components/icons';
import {DateField, InputField, Option, SelectField, ToggleTab, ToggleTabSwitch} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableCell, TableHead, TableRow, TableBody} from '@ace-de/ui-components/data-elements';
import {alfClientTypes, alfInvoiceChannelTypes, alfInvoiceStatusTypes, alfInvoiceTypes, alfInvoiceSubStatusTypes, sortingOptions} from '@ace-de/eua-entity-types';
import routePaths from '../routePaths';
import config from '../config';
import {serviceIcons} from './ui-elements/serviceIcons';
import leaAccessControl from '../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../application/leaFeatures';
import * as applicationActionTypes from '../application/applicationActionTypes';
import warningMessageTypes from '../application/warningMessageTypes';

const initialInvoicesSearchParams = {
    clients: alfClientTypes.ACE,
    sort: 'createdAt,desc',
    phrase: '',
    types: '',
    channels: '',
    statuses: '',
    createdFrom: '',
    createdTo: '',
    hasEKRConnectedVKR: '',
};

const getValuesFromQueryString = queryString => {
    const queryParams = new URLSearchParams(queryString);
    return {
        clients: queryParams.get('clients') || alfClientTypes.ACE,
        phrase: queryParams.get('phrase') || '',
        types: queryParams.get('types') || '',
        channels: queryParams.get('channels') || '',
        statuses: queryParams.get('statuses') || queryParams.get('subStatuses') || '',
        createdFrom: queryParams.get('createdFrom') || '',
        createdTo: queryParams.get('createdTo') || '',
        sort: queryParams.get('sort') || '',
        hasEKRConnectedVKR: queryParams.get('hasEKRConnectedVKR') || '',
    };
};

const InvoicesSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('invoices_search_screen');
    const {invoicesSearchResults, invoicesSearchResultsCount, history} = props;
    const {initiateWarningMessage} = props;
    const [formData, setFormData] = useState(history.location.search
        ? getValuesFromQueryString(history.location.search) : initialInvoicesSearchParams);
    const didComponentMountRef = useRef(false);

    const paginatorCount = Math.ceil(invoicesSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const queryParams = new URLSearchParams(history.location.search);
    const statuses = Object.values(alfInvoiceStatusTypes).concat(Object.values(alfInvoiceSubStatusTypes));

    useEffect(() => {
        if (!didComponentMountRef.current && !queryParams.get('sort')) {
            queryParams.append('sort', 'createdAt,desc');

            history.replace(resolveRoute(routePaths.INVOICES_SEARCH, {}, {search: queryParams.toString()}));
            didComponentMountRef.current = true;
        }
    });

    const getPrice = ({price, currency}) => {
        if (typeof price === 'number' && price >= 0) {
            return price.toLocaleString(activeLocale, {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    const formatQueryParams = formData => {
        if (!formData) return;
        const searchQueryParams = new URLSearchParams();

        // ensure statuses is array
        if (formData['statuses'] && !Array.isArray(formData['statuses'])) {
            formData['statuses'] = [formData['statuses']];
        }

        Object.keys(formData).forEach(formField => {
            if (formField === 'createdFrom' && formData.createdFrom && moment(formData.createdFrom).isValid()) {
                searchQueryParams.append('createdFrom', moment(formData.createdFrom).format('YYYY-MM-DD'));
                return;
            }
            if (formField === 'createdTo' && formData.createdTo && moment(formData.createdTo).isValid()) {
                searchQueryParams.append('createdTo', moment(formData.createdTo).format('YYYY-MM-DD'));
                return;
            }
            if (formField === 'statuses'
                && formData['statuses'].includes(alfInvoiceSubStatusTypes.ELLA_INQUIRY)) {
                searchQueryParams.append('subStatuses', alfInvoiceSubStatusTypes.ELLA_INQUIRY);
                const filteredStatuses = (formData['statuses'] || [])
                    .filter(status => status !== alfInvoiceSubStatusTypes.ELLA_INQUIRY);
                if (filteredStatuses.length) searchQueryParams.append('statuses', filteredStatuses);
                return;
            }
            if (formField === 'statuses'
                && formData['statuses'].includes(alfInvoiceStatusTypes.MANUAL_CHECK)
                && !formData['statuses'].includes(alfInvoiceSubStatusTypes.ELLA_INQUIRY)) {
                searchQueryParams.append('excludedSubStatuses', alfInvoiceSubStatusTypes.ELLA_INQUIRY);
                if (formData['statuses']) searchQueryParams.append('statuses', formData['statuses']);
                return;
            }
            if (formData[formField] !== undefined && formData[formField] !== '') {
                searchQueryParams.append(`${formField}`, formData[formField]);
            }
        });

        return searchQueryParams;
    };

    const handleToggleSwitch = value => {
        const searchQueryParams = formatQueryParams({
            ...formData,
            clients: value,
        });

        handleOnChange('clients', value);
        const queryParamsString = searchQueryParams ? searchQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.INVOICES_SEARCH, {}, {search: queryParamsString}));
    };

    const handleOnChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleResetFilter = () => {
        setFormData(initialInvoicesSearchParams);
        history.push(resolveRoute(routePaths.INVOICES_SEARCH));
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.INVOICES_SEARCH, {}, {search: queryParamsString}));
    };

    const handleOnSubmit = () => {
        const searchQueryParams = formatQueryParams(formData);
        const queryParamsString = searchQueryParams ? searchQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.INVOICES_SEARCH, {}, {search: queryParamsString}));
    };

    const handleOnKeyDown = event => {
        if (event.key !== 'Enter' || (formData.phrase.length > 0 && formData.phrase.length < config.MINIMUM_SEARCH_QUERY_LENGTH)) return;
        handleOnSubmit();
    };

    const openInvoiceDetailsScreen = invoice => {
        const {status} = invoice;
        const isInvoiceOpeningAvailable = leaAccessControl.grantFeatureAccess(leaFeatures[`STATUS_INVOICE_${status}`], leaFeatureActions.READ);
        if (!isInvoiceOpeningAvailable) {
            initiateWarningMessage({warningMessageType: warningMessageTypes.INVOICE_IN_EDITING});
            return;
        }

        switch (status) {
            case alfInvoiceStatusTypes.IN_CREATION: {
                history.push(resolveRoute(routePaths.INVOICES, {
                    serviceCaseId: invoice.serviceCaseId,
                    invoiceId: invoice.id,
                }));
                break;
            }

            case alfInvoiceStatusTypes.OPEN: {
                history.push(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {
                    serviceCaseId: invoice.serviceCaseId,
                }));
                break;
            }

            default: {
                history.push(resolveRoute(routePaths.INVOICE_OVERVIEW, {
                    serviceCaseId: invoice.serviceCaseId,
                    invoiceId: invoice.id,
                }));
            }
        }
    };

    return (
        <Panel
            title={translateScreen('panel_title.invoices_search')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <div className={cx('global!ace-u-margin--0-24-24')}>
                <ToggleTabSwitch
                    name="clients"
                    onChange={value => handleToggleSwitch(value)}
                    value={formData.clients}
                >
                    {Object.values(alfClientTypes).map(tab => (
                        <ToggleTab
                            key={tab}
                            name={`${tab.toLowerCase()}Tab`}
                            value={tab}
                        >
                            {tab}
                        </ToggleTab>
                    ))}
                </ToggleTabSwitch>
            </div>
            <div
                className={cx([
                    'global!ace-u-margin--24-24-32',
                    'global!ace-u-width--full',
                    'global!ace-u-grid',
                    'global!ace-u-flex--align-flex-end',
                    'global!ace-u-flex--justify-space-between',
                ])}
            >
                <div className={cx('global!ace-u-grid-column--span-5')} onKeyDown={handleOnKeyDown}>
                    <InputField
                        name="phrase"
                        label={translateScreen('input_label.search_term')}
                        value={formData.phrase}
                        className={cx('global!ace-u-width--full')}
                        onChange={value => handleOnChange('phrase', value)}
                        placeholder={translateScreen('input_placeholder.search_term')}
                    />
                </div>
                <div className={cx('global!ace-u-grid-column--span-1')}>
                    <SelectField
                        name="types"
                        label={translateScreen('input_label.type')}
                        value={formData.types}
                        isMultipleChoice={true}
                        className={cx('global!ace-u-width--full')}
                        onChange={value => handleOnChange('types', value)}
                    >
                        {Object.values(alfInvoiceTypes).map(invoiceType => (
                            <Option
                                name={`${invoiceType.toLowerCase()}Option`}
                                key={invoiceType}
                                value={invoiceType}
                            >
                                {invoiceType}
                            </Option>
                        ))}
                    </SelectField>
                </div>
                <div className={cx('global!ace-u-grid-column--span-1')}>
                    <SelectField
                        name="channels"
                        label={translateScreen('input_label.channel')}
                        value={formData.channels}
                        isMultipleChoice={true}
                        className={cx('global!ace-u-width--full')}
                        onChange={value => handleOnChange('channels', value)}
                    >
                        {Object.values(alfInvoiceChannelTypes).map(channelType => (
                            <Option
                                name={`${channelType.toLowerCase()}Option`}
                                key={channelType}
                                value={channelType}
                            >
                                {channelType}
                            </Option>
                        ))}
                    </SelectField>
                </div>
                <div className={cx('global!ace-u-grid-column--span-1')}>
                    <SelectField
                        name="statuses"
                        label={translateScreen('input_label.status')}
                        value={formData.statuses}
                        isMultipleChoice={true}
                        className={cx('global!ace-u-width--full')}
                        onChange={value => handleOnChange('statuses', value)}
                    >
                        {statuses.map(statusType => (
                            <Option
                                name={`${statusType.toLowerCase()}Option`}
                                key={statusType}
                                value={statusType}
                            >
                                {translate(`global.invoice_status.${statusType.toLowerCase()}`)}
                            </Option>
                        ))}
                    </SelectField>
                </div>
                <div className={cx('global!ace-u-grid-column--span-1')}>
                    <SelectField
                        name="hasEKRConnectedVKR"
                        className={cx('global!ace-u-width--full')}
                        value={formData.hasEKRConnectedVKR}
                        label={translateScreen('input_label.has_ekr_connected_vkr')}
                        onChange={value => handleOnChange('hasEKRConnectedVKR', value)}
                    >
                        <Option
                            name="hasEKRConnectedVKRYes"
                            value="true"
                        >
                            {translateScreen('has_ekr_connected_vkr.select_option_label.yes')}
                        </Option>
                        <Option
                            name="hasEKRConnectedVKRNo"
                            value="false"
                        >
                            {translateScreen('has_ekr_connected_vkr.select_option_label.no')}
                        </Option>
                    </SelectField>
                </div>
                <div className={cx('global!ace-u-grid-column--span-1')}>
                    <DateField
                        name="createdFrom"
                        label={translateScreen('input_label.period_from')}
                        value={formData.createdFrom}
                        maxDate={formData.createdTo ? moment(formData.createdTo).format() : ''}
                        icon={calendarIcon}
                        className={cx('global!ace-u-width--full', 'ace-c-date-field__input--medium')}
                        onChange={value => handleOnChange('createdFrom', value)}
                    />
                </div>
                <div className={cx('global!ace-u-grid-column--span-1')}>
                    <DateField
                        name="createdTo"
                        label={translateScreen('input_label.period_to')}
                        value={formData.createdTo}
                        minDate={formData.createdFrom ? moment(formData.createdFrom).format() : ''}
                        icon={calendarIcon}
                        className={cx('global!ace-u-width--full', 'ace-c-date-field__input--medium')}
                        onChange={value => handleOnChange('createdTo', value)}
                    />
                </div>
                <ButtonIcon
                    name="searchButton"
                    icon={searchIcon}
                    onClick={handleOnSubmit}
                    className={cx([
                        'global!ace-u-flex--basis-5',
                        'global!ace-u-width--64',
                        'global!ace-u-height--48',
                    ])}
                    isDisabled={
                        formData.phrase.length > 0
                        && formData.phrase.length < config.MINIMUM_SEARCH_QUERY_LENGTH
                    }
                />
            </div>
            <div
                className={cx([
                    'global!ace-u-margin--right-24',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <RadioButtonGroup
                    name="sort"
                    value={queryParams.get('sort') || formData?.sort}
                    onChange={value => handleOnChange('sort', value)}
                >
                    {Object.values(sortingOptions).reverse().map((sortOption, id) => (
                        <RadioButton
                            className={cx('global!ace-u-margin--right-24')}
                            key={`${sortOption}-${id}`}
                            name={sortOption}
                            value={sortOption === sortingOptions.OLDEST_TO_NEWEST
                                ? 'createdAt,asc' : 'createdAt,desc'}
                        >
                            {translateScreen(`sorting_option.${sortOption.toLowerCase()}`)}
                        </RadioButton>
                    ))}
                </RadioButtonGroup>
                <InteractiveIcon
                    icon={resetIcon}
                    className={cx([
                        'ace-c-interactive-icon--reverse',
                        'ace-c-interactive-icon--highlight',
                    ])}
                    onClick={handleResetFilter}
                >
                    {translateScreen('interactive_icon_label.reset_filter')}
                </InteractiveIcon>
            </div>
            <p className={cx(['global!ace-u-typography--variant-h3', 'global!ace-u-margin--0-24-24'])}>
                {translateScreen('table_title.results', {totalCount: invoicesSearchResultsCount})}
            </p>
            <Table qaIdent="invoice-search-results" className={cx('global!ace-u-padding--64')}>
                {invoicesSearchResults.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            description={translateScreen('no_results.description')}
                            message={translateScreen('no_results.message')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell
                            colSpan={1}
                            qaIdentPart="invoice-type"
                            className={cx('ace-u-padding--left-24')}
                        >
                            {translateScreen('table_data_row.type')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-status">
                            {translateScreen('table_data_row.status')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-id">
                            {translateScreen('table_data_row.invoice_id')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice- created-at">
                            {translateScreen('table_data_row.date')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="invoice-channel">
                            {translateScreen('table_data_row.channel')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-account-party-name">
                            {translateScreen('table_data_row.creditor_debtor')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-service-lines">
                            {translateScreen('table_data_row.services')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-total-invoiced-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {translateScreen('table_data_row.total_amount')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-commissioner"
                            className={cx('ace-u-padding--right-24')}
                        >
                            {translateScreen('table_data_row.commissioner')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-has-ekr-connected-vkr"
                            className={cx('ace-u-padding--right-24')}
                        >
                            {translateScreen('table_data_row.has_ekr_connected_vkr')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-case-id"
                            className={cx('ace-u-padding--right-24')}
                        >
                            {translateScreen('table_data_row.service_case_id')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoicesSearchResults.map(invoice => {
                        const getInvoiceStatusBackgroundType = (status, subStatus) => {
                            let colorType = 'pending-bold-highlighted';
                            if (subStatus && subStatus === alfInvoiceSubStatusTypes.ELLA_INQUIRY) {
                                colorType = 'negative-bold-highlighted';
                                return colorType;
                            }
                            if (status === alfInvoiceStatusTypes.IN_CREATION) colorType = 'information-bold-highlighted';
                            if (status === alfInvoiceStatusTypes.APPROVED
                                || status === alfInvoiceStatusTypes.PAID
                                || status === alfInvoiceStatusTypes.BOOKED) {
                                colorType = 'positive-bold-highlighted';
                            }
                            if (status === alfInvoiceStatusTypes.MANUAL_CHECK
                                || status === alfInvoiceStatusTypes.BC_ERROR) colorType = 'negative-bold-highlighted';
                            return colorType;
                        };
                        return (
                            <TableRow
                                key={invoice.id}
                                onClick={() => openInvoiceDetailsScreen(invoice)}
                                qaIdentPart={invoice.id}
                            >
                                <TableCell
                                    colSpan={1}
                                    qaIdentPart="invoice-type"
                                    qaIdentPartPostfix={invoice.id}
                                    className={cx('ace-u-padding--left-24')}
                                >
                                    {invoice.type || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-status" qaIdentPartPostfix={invoice.id}>
                                    {invoice.status
                                        ? (
                                            <Pill
                                                type={getInvoiceStatusBackgroundType(invoice.status, invoice.subStatus)}
                                                className={cx([
                                                    'global!ace-u-padding--4-16',
                                                    'global!ace-u-width--192',
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--justify-center',
                                                ])}
                                            >
                                                {translate(`global.invoice_status.${invoice.subStatus
                                                    ? invoice.subStatus.toLowerCase()
                                                    : invoice.status.toLowerCase()}`)}
                                            </Pill>
                                        )
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-id" qaIdentPartPostfix={invoice.id}>
                                    {invoice.id || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-created-at" qaIdentPartPostfix={invoice.id}>
                                    {invoice.createdAt || '-'}
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="invoice-channel" qaIdentPartPostfix={invoice.id}>
                                    {invoice.channel || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-account-party-name" qaIdentPartPostfix={invoice.id}>
                                    {invoice.accountParty?.name || invoice.accountParty?.name2 || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-service-lines" qaIdentPartPostfix={invoice.id}>
                                    <div className={cx('global!ace-u-inline-flex')}>
                                        {invoice.lines.length > 0 && serviceIcons[invoice.lines[0].service]?.icon && (
                                            <Icon
                                                icon={serviceIcons[invoice.lines[0].service].icon}
                                                className={cx('global!ace-u-margin--right-8')}
                                            />
                                        )}
                                        {invoice.lines.length > 1
                                            ? `${translate(`global.service_type.${invoice.lines[0].service.toLowerCase()}`)}
                                        +${invoice.lines.length - 1}`
                                            : invoice.lines[0]?.service
                                                ? translate(`global.service_type.${invoice.lines[0].service.toLowerCase()}`)
                                                : '-'}
                                    </div>
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-total-invoice-amount"
                                    qaIdentPartPostfix={invoice.id}
                                    className={cx('global!ace-u-typography--align-right')}
                                >
                                    {getPrice({
                                        price: invoice.totalInvoicedAmount,
                                        currency: config.CURRENCY,
                                    }) || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-commissioner"
                                    qaIdentPartPostfix={invoice.id}
                                    className={cx('ace-u-padding--right-24')}
                                >
                                    {invoice?.commissionerName || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-has-ekr-connected-vkr"
                                    qaIdentPartPostfix={invoice.id}
                                    className={cx('ace-u-padding--right-24')}
                                >
                                    {invoice?.hasEKRConnectedVKR
                                        ? translateScreen('table_data_row.has_ekr_connected_vkr_yes') : '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-case-id"
                                    qaIdentPartPostfix={invoice.id}
                                    className={cx('ace-u-padding--right-24')}
                                >
                                    {invoice.serviceCaseId || '-'}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {invoicesSearchResultsCount > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={page => handlePaginationPage(page)}
                />
            )}
        </Panel>
    );
};

InvoicesSearchScreen.propTypes = {
    invoicesSearchResults: PropTypes.array,
    invoicesSearchResultsCount: PropTypes.number,
    history: PropTypes.object.isRequired,
    initiateWarningMessage: PropTypes.func.isRequired,
};

InvoicesSearchScreen.defaultProps = {
    invoicesSearchResults: [],
    invoicesSearchResultsCount: 0,
};

const mapStateToProps = state => ({
    invoicesSearchResults: state.invoices.invoiceSearchResults,
    invoicesSearchResultsCount: state.invoices.invoiceSearchResultsCount,
});

const mapDispatchToProps = dispatch => ({
    initiateWarningMessage: (payload => dispatch({
        type: applicationActionTypes.INITIATE_WARNING_MESSAGE_FLOW,
        payload,
    })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesSearchScreen));
