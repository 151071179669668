import * as taskActionTypes from '../tasks/taskActionTypes';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import errorTypes from './errorTypes';

const determineActionTypes = requestedAction => {
    if (!requestedAction) return null;

    switch (requestedAction) {
        case errorTypes.TASK_CREATION_FAILED:
        case errorTypes.TASK_CREATION_ASSIGNEE_MISSING: {
            return {
                confirm: taskActionTypes.RETRY_TASK_CREATION,
                decline: taskActionTypes.CANCEL_RETRY_TASK_CREATION,
            };
        }

        case errorTypes.TASK_EDIT_FAILED:
        case errorTypes.TASK_EDIT_ASSIGNEE_MISSING: {
            return {
                confirm: taskActionTypes.RETRY_TASK_EDIT,
                decline: taskActionTypes.CANCEL_RETRY_TASK_EDIT,
            };
        }

        case errorTypes.MESSAGE_SEND_FAILED: {
            return {
                confirm: communicationActionTypes.RETRY_MESSAGE_SEND,
                decline: communicationActionTypes.CANCEL_RETRY_MESSAGE_SEND,
            };
        }

        case errorTypes.MESSAGE_UPLOAD_FAILED: {
            return {
                confirm: communicationActionTypes.RETRY_MESSAGE_UPLOAD,
                decline: communicationActionTypes.CANCEL_RETRY_MESSAGE_UPLOAD,
            };
        }

        // todo add other actions
        default: return {
            confirm: null,
            decline: null,
        };
    }
};

export default determineActionTypes;
