import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, ScreenTitle, Icon, InteractiveIcon, closeIcon, assignmentIcon, Tooltip} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import goToParentRoute from '../../utils/goToParentRoute';
import config from '../../config';

const ServiceCaseHeader = props => {
    const {cx} = useStyles();
    const {serviceCase, history, match} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateHeader = createTranslateShorthand('service_case_header');

    if (!serviceCase) return null;

    const {member} = serviceCase;

    return (
        <Fragment>
            <ScreenTitle>
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                    <Icon
                        icon={assignmentIcon}
                        className={cx([
                            'ace-c-icon--color-contrast',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    <p>
                        {translateHeader('screen_title.service_case') }
                        <span>{`${serviceCase.prefix}-${serviceCase.id}`}</span>
                    </p>
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx([
                            'ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--right-32',
                            'global!ace-u-flex',
                        ])}
                    >
                        <p>
                            {translateHeader('screen_title.member') }
                            <span className={cx('global!ace-u-typography--variant-body')}>
                                {member.personalDetails
                                    ? member.membershipType === ampMembershipTypes.PRIVATE
                                        ? ` ${member.personalDetails.firstName} ${member.personalDetails.surname}`
                                        : ` ${member.personalDetails.name}`
                                    : ' -'
                                }
                            </span>
                        </p>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--right-32',
                            'global!ace-u-flex',
                        ])}
                    >
                        <p>
                            {translateHeader('screen_title.client') }
                            <span className={cx('global!ace-u-typography--variant-body')}>
                                {serviceCase.client || ' -'}
                            </span>
                        </p>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--right-32',
                            'global!ace-u-flex',
                        ])}
                    >
                        <p>
                            {translateHeader('screen_title.commissioner') }
                            <span className={cx('global!ace-u-typography--variant-body')}>
                                {serviceCase.commissioner || ' -'}
                            </span>
                        </p>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--right-32',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-baseline',
                        ])}
                    >
                        <p>
                            {translateHeader('screen_title.damage_date')}
                            <span>
                                {serviceCase?.damage?.date
                                    ? moment(serviceCase.damage.date).format('DD.MM.YYYY')
                                    : '-'}
                            </span>
                        </p>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--right-32',
                            'global!ace-u-flex',
                        ])}
                    >
                        <p>
                            {translateHeader('screen_title.damage_location')}
                        </p>
                        <span
                            className={cx([
                                'global!ace-u-margin--left-4',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {serviceCase.damage?.location?.address?.formattedAddress
                                ? serviceCase.damage.location.address.formattedAddress.length
                                    > config.DAMAGE_LOCATION_CHAR_LIMIT
                                    ? (
                                        <Tooltip message={serviceCase.damage.location.address.formattedAddress}>
                                            <div
                                                className={cx('ace-c-invoice-overview-service-panel__additional-info')}
                                            >
                                                {`${serviceCase.damage.location.address.formattedAddress.substring(0, config.DAMAGE_LOCATION_CHAR_LIMIT)}...`}
                                            </div>
                                        </Tooltip>
                                    )
                                    : serviceCase.damage.location.address.formattedAddress
                                : '-'}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--right-32',
                            'global!ace-u-flex',
                        ])}
                    >
                        <p>
                            {translateHeader('screen_title.damage_to_member_location')}
                            <span className={cx('global!ace-u-typography--variant-body')}>
                                {serviceCase.residenceToDamageLocationDistance
                                    ? `${Math.round(serviceCase.residenceToDamageLocationDistance)} ${translate('global.unit.km')}`
                                    : '-'
                                }
                            </span>
                        </p>
                    </div>
                    {/* todo wait for BE to provide it */}
                    {/* <div */}
                    {/*    className={cx([ */}
                    {/*        'global!ace-u-typography--variant-body-bold', */}
                    {/*        'global!ace-u-margin--right-32', */}
                    {/*        'global!ace-u-flex', */}
                    {/*    ])} */}
                    {/* > */}
                    {/*    <p> */}
                    {/*        {translateHeader('screen_title.costs')} */}
                    {/*        <span className={cx('global!ace-u-typography--variant-body')}> */}
                    {/*            -*/}
                    {/*        </span> */}
                    {/*    </p> */}
                    {/* </div> */}
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={() => goToParentRoute({
                            currentPath: match.path,
                            history,
                        })}
                    />
                </div>
            </div>
        </Fragment>
    );
};

ServiceCaseHeader.propTypes = {
    serviceCase: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

ServiceCaseHeader.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ServiceCaseHeader));
