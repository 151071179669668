import {fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {fetchRequest} from '@computerrock/formation-core';
import {alfDestinationTypes, alfRelevantLocationTypes, InvoiceLine} from '@ace-de/eua-entity-types';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';

const invoiceLineEditFlow = function* invoiceLineEditFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_LINE_EDIT_FLOW);
        const {lineNo} = payload;

        yield* openModal(modalIds.INVOICE_LINE_DATA, {lineNo: lineNo.toString()});

        const chosenModalOption = yield take([
            invoiceActionTypes.CONFIRM_EDIT_INVOICE_LINE,
            invoiceActionTypes.DECLINE_EDIT_INVOICE_LINE,
        ]);

        if (chosenModalOption.type === invoiceActionTypes.CONFIRM_EDIT_INVOICE_LINE) {
            const {payload} = chosenModalOption;
            const {invoiceId, invoiceLineData, lineNo} = payload;

            yield fork(
                fetchRequest,
                invoiceActionTypes.UPDATE_INVOICE_SERVICE_LINES_REQUEST,
                leaAssignmentFlowService.updateInvoiceLines, {
                    invoiceId,
                    lineNo,
                    invoiceLinesData: InvoiceLine.objectToDTO({
                        ...invoiceLineData,
                        ...(invoiceLineData.destination && {
                            destination: {
                                ...invoiceLineData.destination,
                                type: alfRelevantLocationTypes.SERVICE,
                                destinationType: alfDestinationTypes.OTHER_LOCATION,
                            },
                        }),
                    }),
                },
            );

            yield take([
                invoiceActionTypes.UPDATE_INVOICE_SERVICE_LINES_REQUEST_SUCCEEDED,
                invoiceActionTypes.UPDATE_INVOICE_SERVICE_LINES_REQUEST_FAILED,
            ]);
        }

        yield* closeModal(modalIds.INVOICE_LINE_DATA, {lineNo: lineNo.toString()});
    }
};

export default invoiceLineEditFlow;
