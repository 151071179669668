import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {camelCase} from 'change-case';
import {connect} from 'react-redux';
import moment from 'moment';
import {resolveRoute, withRouter, prepareSearchQueryParams} from '@computerrock/formation-router';
import {alfServiceCaseStatusTypes, ampMembershipTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ToggleTabSwitch, ToggleTab, Form, InputField, SelectField, Option, DateField, ButtonIcon, NoResultsBlock, Paginator, Pill, Badge} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {calendarIcon, searchIcon, findCaseIcon, resetIcon, editIcon, InteractiveIcon, Icon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import dashboardSearchTabs from '../dashboard/dashboardSearchTabs';
import routePaths from '../routePaths';
import config from '../config';
import leaAccessControl from '../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../application/leaFeatures';
import * as applicationActionTypes from '../application/applicationActionTypes';
import warningMessageTypes from '../application/warningMessageTypes';

const initialServiceCaseFilteringParams = {
    searchTerm: '',
    status: [],
    commissionerIds: [],
    createdAtAfter: '',
    createdAtBefore: '',
};

const getValuesFromQueryString = queryString => {
    const queryParams = new URLSearchParams(queryString);
    return {
        searchTerm: queryParams.get('searchTerm') || initialServiceCaseFilteringParams.searchTerm,
        status: queryParams.get('status')?.split(',') || initialServiceCaseFilteringParams.status,
        commissionerIds: queryParams.get('commissionerIds')?.split(',')
            || initialServiceCaseFilteringParams.commissionerIds,
        createdAtAfter: queryParams.get('createdAtAfter') || '',
        createdAtBefore: queryParams.get('createdAtBefore') || '',
    };
};

const ServiceCaseSearchScreen = props => {
    const {cx} = useStyles();
    const {history, serviceCaseSearchResults, serviceCaseSearchResultsCount, initiateWarningMessage} = props;
    const {commissioners} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('service_case_search_screen');
    const [selectedTab] = useState(dashboardSearchTabs.CASE);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [filterFormData, setFilterFormData] = useState(history.location.search
        ? getValuesFromQueryString(history.location.search) : initialServiceCaseFilteringParams);
    const paginatorCount = Math.ceil(serviceCaseSearchResultsCount / config.DEFAULT_PAGE_SIZE);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const openServiceCaseOverview = serviceCaseId => {
        const selectedCase = serviceCaseSearchResults.find(serviceCase => serviceCase.id === serviceCaseId);
        const {status} = selectedCase;
        const isCaseOpeningAvailable = leaAccessControl.grantFeatureAccess(leaFeatures[`STATUS_CASE_${status}`], leaFeatureActions.READ);

        if (isCaseOpeningAvailable) {
            history.push(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {serviceCaseId}));
        }
        if (!isCaseOpeningAvailable) {
            initiateWarningMessage({warningMessageType: warningMessageTypes.SERVICE_CASE_IN_EDITING});
            return;
        }
    };

    const formatQueryParams = filterFormData => {
        if (!filterFormData) return;
        const searchQueryParams = new URLSearchParams();

        Object.keys(filterFormData).forEach(formField => {
            if (formField === 'createdAtBefore' && filterFormData.createdAtBefore && moment(filterFormData.createdAtBefore).isValid()) {
                const date = new Date(filterFormData['createdAtBefore']);
                const endDate = moment(date.setHours(23, 59, 59, 59)).format();
                searchQueryParams.append('createdAtBefore', endDate);
                return;
            }
            if (filterFormData[formField] !== undefined && filterFormData[formField] !== '' && filterFormData[formField].length) {
                searchQueryParams.append(`${formField}`, filterFormData[formField]);
            }
        });

        return searchQueryParams;
    };

    const handleOnTabSwitch = value => {
        if (value === dashboardSearchTabs.MEMBER) {
            history.push(resolveRoute(
                routePaths.MEMBER_SEARCH,
                {},
            ));
        }
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.SERVICE_CASES_SEARCH, {}, {search: queryParamsString}));
    };

    const handleRedirectToServiceCase = (event, serviceCaseId) => {
        event.stopPropagation();
        history.push(resolveRoute(routePaths.SERVICE_CASE,
            {serviceCaseId},
            {
                search: prepareSearchQueryParams({isEditModeActive: 'true'}),
                state: {isFromCaseSearch: 'true'},
            }));
    };

    const handleResetFilter = () => {
        setFilterFormData(initialServiceCaseFilteringParams);
        history.push(resolveRoute(routePaths.SERVICE_CASES_SEARCH));
    };

    const handleOnChange = (key, value) => {
        setFilterFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleOnSubmit = () => {
        const searchQueryParams = formatQueryParams({
            ...filterFormData,
        });

        history.push(resolveRoute(routePaths.SERVICE_CASES_SEARCH, {}, {
            search: searchQueryParams.toString(),
        }));
    };

    const handleOnKeyDown = event => {
        if (event.key !== 'Enter' || (filterFormData.searchTerm.length > 0 && filterFormData.searchTerm.length < config.MINIMUM_SEARCH_QUERY_LENGTH)) return;
        handleOnSubmit();
    };

    return (
        <Panel
            title={(
                <p className={cx('global!ace-u-margin--24')}>
                    {translateScreen('panel_title.search')}
                </p>
            )}
            className={cx('ace-c-panel--full-bleed')}
        >
            <div className={cx('global!ace-u-margin--0-24-24')}>
                <div className={cx('global!ace-u-margin--bottom-24')}>
                    <ToggleTabSwitch
                        name="memberCaseSearch"
                        onChange={handleOnTabSwitch}
                        value={selectedTab}
                    >
                        {Object.values(dashboardSearchTabs).map(tab => (
                            <ToggleTab
                                key={tab}
                                name={`${tab.toLowerCase()}Tab`}
                                value={tab}
                            >
                                {translate(`global.search_type.${tab.toLowerCase()}`).toUpperCase()}
                            </ToggleTab>
                        ))}
                    </ToggleTabSwitch>
                    <Form name="serviceCaseSearchForm">
                        <div
                            className={cx([
                                'global!ace-u-margin--top-24',
                                'global!ace-u-grid',
                            ])}
                            onKeyDown={handleOnKeyDown}
                        >
                            <InputField
                                name="searchTerm"
                                className={cx('global!ace-u-grid-column--span-5')}
                                label={translateScreen('input_field_label.free_text_search')}
                                placeholder={translateScreen('input_field_placeholder.case_id')}
                                value={filterFormData.searchTerm}
                                onChange={value => handleOnChange('searchTerm', value)}
                            />
                            <SelectField
                                name="status"
                                label={translateScreen('select_field_label.status')}
                                placeholder={translateScreen('select_field_placeholder.all_cases')}
                                className={cx('global!ace-u-grid-column--span-2')}
                                isMultipleChoice={true}
                                value={filterFormData.status}
                                onChange={value => handleOnChange('status', value)}
                            >
                                {Object.keys(alfServiceCaseStatusTypes).map(alfServiceCaseStatus => {
                                    return (
                                        <Option
                                            name={`option${camelCase(alfServiceCaseStatus)}`}
                                            value={alfServiceCaseStatus}
                                            key={alfServiceCaseStatus}
                                        >
                                            {translate(`global.service_case_status.${alfServiceCaseStatus.toLowerCase()}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            <SelectField
                                name="commissionerIds"
                                label={translateScreen('select_field_label.commissioner')}
                                placeholder={translateScreen('select_field_placeholder.all')}
                                className={cx('global!ace-u-grid-column--span-2')}
                                isMultipleChoice={true}
                                value={filterFormData.commissionerIds}
                                onChange={value => handleOnChange('commissionerIds', value)}
                            >
                                {Object.keys(commissioners).map(key => {
                                    return (
                                        <Option
                                            name={`option-${key}`}
                                            value={key}
                                            key={key}
                                        >
                                            {commissioners[key].name}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            <DateField
                                name="createdAtAfter"
                                icon={calendarIcon}
                                label={translateScreen('date_field_label.start_date')}
                                className={cx('global!ace-u-grid-column--span-1', 'ace-c-date-field__input--medium')}
                                value={filterFormData.createdAtAfter}
                                onChange={value => handleOnChange('createdAtAfter', value)}
                            />
                            <DateField
                                name="createdAtBefore"
                                label={translateScreen('date_field_label.end_date')}
                                icon={calendarIcon}
                                className={cx('global!ace-u-grid-column--span-1', 'ace-c-date-field__input--medium')}
                                value={filterFormData.createdAtBefore}
                                onChange={value => handleOnChange('createdAtBefore', value)}
                            />
                            <div className={cx('global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24')}>
                                <ButtonIcon
                                    name="searchButton"
                                    onClick={handleOnSubmit}
                                    icon={searchIcon}
                                    className={cx([
                                        'global!ace-u-flex--basis-5',
                                        'global!ace-u-width--64',
                                        'global!ace-u-height--48',
                                    ])}
                                    isDisabled={filterFormData.searchTerm.length > 0
                                    && filterFormData.searchTerm.length < config.MINIMUM_SEARCH_QUERY_LENGTH}
                                />
                            </div>
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-end',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            <InteractiveIcon
                                icon={resetIcon}
                                className={cx([
                                    'ace-c-interactive-icon--reverse',
                                    'ace-c-interactive-icon--highlight',
                                    'global!ace-u-margin--right-32',

                                ])}
                                onClick={handleResetFilter}
                            >
                                {translateScreen('interactive_icon_label.reset_filter')}
                            </InteractiveIcon>
                        </div>
                    </Form>
                </div>
            </div>
            <div>
                <p
                    className={cx([
                        'global!ace-u-margin--0-24-24',
                        'global!ace-u-typography--variant-h3',
                    ])}
                >
                    {translateScreen('table_header_title.count', {count: serviceCaseSearchResultsCount})}
                </p>
                <Table qaIdent="service-case-search-results">
                    {serviceCaseSearchResults.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-disabled')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateScreen('no_results.message')}
                                description={translateScreen('no_results.description')}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell
                                qaIdentPart="sc-damage-date"
                                className={cx('ace-u-padding--left-24')}
                                colSpan={2}
                            >
                                {translateScreen('table_header.damage_date')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-case-id" colSpan={3}>
                                {translateScreen('table_header.case_id')}
                            </TableCell>
                            <TableCell qaIdentPart="member-id" colSpan={2}>
                                {translateScreen('table_header.member_id')}
                            </TableCell>
                            <TableCell qaIdentPart="member-name" colSpan={3}>
                                {translateScreen('table_header.name')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-damage-location" colSpan={3}>
                                {translateScreen('table_header.damage_location')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-vehicle-license-plate" colSpan={2}>
                                {translateScreen('table_header.license_plate')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-commissioner" colSpan={2}>
                                {translateScreen('table_header.commissioner')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-external-case-id" colSpan={3}>
                                {translateScreen('table_header.external_case_id')}
                            </TableCell>
                            <TableCell
                                qaIdentPart="sc-case-status"
                                className={cx('ace-u-padding--right-24')}
                                colSpan={3}
                            >
                                {translateScreen('table_header.case_status')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-amount-of-invoices" colSpan={1}>
                                {translateScreen('table_header.amount_of_invoices')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-tasks-count" colSpan={1}>
                                {translateScreen('table_header.tasks_count')}
                            </TableCell>
                            <TableCell colSpan={1} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceCaseSearchResults.map(serviceCase => {
                            const {damage, member} = serviceCase;
                            const damageLocation = damage?.location?.address
                                ? `${damage.location.address.postCode} ${damage.location.address.city}`
                                : '-';
                            const memberName = member?.personalDetails
                                ? member.personalDetails.membershipType === ampMembershipTypes.CORPORATE
                                || serviceCase.id.includes('MIA') || serviceCase.id.includes('MOA')
                                    ? member.personalDetails.name
                                    : `${member.personalDetails.firstName} ${member.personalDetails.surname}`
                                : '-';
                            const hasEditRights = leaAccessControl.grantFeatureAccess(
                                `STATUS_CASE_${serviceCase.status}`,
                                leaFeatureActions.UPDATE,
                            );

                            return (
                                <TableRow
                                    key={serviceCase.id}
                                    onClick={() => openServiceCaseOverview(serviceCase.id)}
                                    qaIdentPart={serviceCase.id}
                                >
                                    <TableCell
                                        qaIdentPart="sc-damage-date"
                                        qaIdentPartPostfix={serviceCase.id}
                                        className={cx('ace-u-padding--left-24')}
                                        colSpan={2}
                                    >
                                        {serviceCase.damage?.date
                                            ? moment(serviceCase.damage.date).format('DD.MM.YYYY')
                                            : '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-case-id"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={3}
                                    >
                                        {`${serviceCase.prefix}-${serviceCase.id}` || '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="member-id"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={2}
                                    >
                                        {member?.membershipNo || '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="member-name"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={3}
                                    >
                                        {memberName}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-damage-location"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={3}
                                    >
                                        {damageLocation}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-vehicle-license-plate"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={2}
                                    >
                                        {serviceCase.vehicle?.licensePlateNumber || '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-commissioner"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={2}
                                    >
                                        {serviceCase.commissioner || '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-external-case-id"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={3}
                                    >
                                        {serviceCase.externalCaseId || '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-case-status"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={3}
                                    >
                                        <Pill
                                            className={cx({
                                                'ace-c-pill--negative': serviceCase.status === alfServiceCaseStatusTypes.NEW,
                                                'ace-c-pill--information': serviceCase.status === alfServiceCaseStatusTypes.OPEN,
                                                'ace-c-pill--positive': serviceCase.status === alfServiceCaseStatusTypes.CLOSED,
                                            })}
                                        >
                                            {translate(`global.service_case_status.${serviceCase.status.toLowerCase()}`)}
                                        </Pill>
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-amount-of-invoices"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={1}
                                    >
                                        <Badge className={cx('ace-c-badge--status-information')}>
                                            {serviceCase.invoicesInfo?.invoicesCount || 0}
                                        </Badge>
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-tasks-count"
                                        qaIdentPartPostfix={serviceCase.id}
                                        colSpan={1}
                                    >
                                        <Badge
                                            className={cx({
                                                'ace-c-badge--status-negative': serviceCase.tasksCount > 0,
                                                'ace-c-badge--status-information': !serviceCase.tasksCount || serviceCase.tasksCount === 0,
                                            })}
                                        >
                                            {serviceCase.tasksCount || 0}
                                        </Badge>
                                    </TableCell>
                                    <TableCell className={cx('ace-u-padding--right-24')} colSpan={1}>
                                        {serviceCase.isEditable && hasEditRights && (
                                            <InteractiveIcon
                                                icon={editIcon}
                                                onClick={event => handleRedirectToServiceCase(event, serviceCase.id)}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            {serviceCaseSearchResultsCount > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={page => handlePaginationPage(page)}
                />
            )}
        </Panel>
    );
};

ServiceCaseSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    serviceCaseSearchResults: PropTypes.array,
    serviceCaseSearchResultsCount: PropTypes.number,
    initiateWarningMessage: PropTypes.func.isRequired,
    commissioners: PropTypes.object,
};

ServiceCaseSearchScreen.defaultProps = {
    serviceCaseSearchResults: [],
    serviceCaseSearchResultsCount: 0,
    commissioners: null,
};

const mapStateToProps = state => ({
    serviceCaseSearchResults: state.serviceCases.serviceCaseSearchResults,
    serviceCaseSearchResultsCount: state.serviceCases.serviceCasesTotalCount,
    commissioners: state.serviceCases.commissioners,
});

const mapDispatchToProps = dispatch => ({
    initiateWarningMessage: payload => dispatch({
        type: applicationActionTypes.INITIATE_WARNING_MESSAGE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceCaseSearchScreen));
